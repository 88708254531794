import Keycloak from 'keycloak-js';
import { store } from 'store';
import { sendAuthMsg } from './authChannelSlice';

const kc = new Keycloak('/keycloak.json');

// Initialize Keycloak and return a promise
const initializeKeycloak = () => {
  return new Promise((resolve, reject) => {
    kc.init({ onLoad: 'check-sso', promiseType: 'native' })
      .then((authenticated) => {
        if (authenticated) {
          console.info('Authenticated successfully.');
          sessionStorage.setItem('authentication', kc.token);
          sessionStorage.setItem('refreshToken', kc.refreshToken);
          store.dispatch(sendAuthMsg('authenticated'));
          resolve();
        } else {
          console.warn('Not authenticated, redirecting to login.');
          kc.login();
          reject(new Error('Not authenticated'));
        }
      })
      .catch((error) => {
        console.error('Authentication failed:', error);
        kc.login();
        reject(error);
      });
  });
};
const doLogin = () => kc.login();
const doLogout = () => kc.logout({ redirectUri: window.location.origin });


// Define the UserService object with methods for authentication and user information
const UserService = {
  doLogin,
  doLogout,
  initialize: initializeKeycloak,
  getToken: () => kc.token,
  isLoggedIn: () => !!kc.token,
  updateToken: (successCallback) => {
    return kc.updateToken(5)
      .then(successCallback)
      .catch(() => kc.login()); // Handle token update failure
  },
  getUserId: () => kc.tokenParsed?.sub,
  getEmail: () => kc.tokenParsed?.email,
  getUsername: () => kc.tokenParsed?.preferred_username,
  getFirstName: () => kc.tokenParsed?.given_name,
  getLastName: () => kc.tokenParsed?.family_name,
  hasRole: (roles) => roles.some((role) => kc.hasRealmRole(role)),
  hasGuestRole: () => kc.hasResourceRole('Guest'),
  hasClientRole: () => kc.hasResourceRole('Client'),
  hasAnalyticsRole: () => kc.hasResourceRole('Analytics'),
  hasSelfAssessmentRole: () => kc.hasResourceRole('Self_Assessment'),
  hasForthPortSupplierRole: () => kc.hasResourceRole('ForthPort Supplier'),
  hasForthPortRole: () => kc.hasResourceRole('ForthPort'),
};

export default UserService;
