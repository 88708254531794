import { createSlice } from "@reduxjs/toolkit";

export const RegulationSlice = createSlice({
    name: 'DashboardRegulation',
    initialState: {
      items: [],
      pageInfo: {
        page: 0,
        pageSize: 25,
        rowCount: 0,
        pageCount: 0,
      },
    },
    reducers: {
      setRegulationPage(state, action) {
        state.pageInfo = {
          ...state.pageInfo,
          page: action.payload.page,
          pageSize: action.payload.pageSize,
        };
      },
      getData(state, action) {
        state.items = action.payload.data;
        state.pageInfo = {
          ...state.pageInfo,
          // page: action.payload.pageInfo.currentPage,
          rowCount: action.payload.pageInfo.totalElement,
          pageCount: action.payload.pageInfo.totalPages,
        };
      },
    },
  });

  export const {getData, setRegulationPage}= RegulationSlice.actions
  const regulationReducer= RegulationSlice.reducer;
  export default regulationReducer