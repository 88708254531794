export const DIMENSION = {
  Desktop: 'desktop',
  Tablet: 'tablet',
  Mobile: 'mobile',
};

export const LAYOUT = {
  Fluid: 'fluid',
  Boxed: 'boxed',
};

export const RADIUS = {
  Rounded: 'rounded',
  Standard: 'standard',
  Flat: 'flat',
};

export const THEME_COLOR = {
  LightBlue: 'light-blue',
  DarkBlue: 'dark-blue',
  LightRed: 'light-red',
  DarkRed: 'dark-red',
  LightGreen: 'light-green',
  DarkGreen: 'dark-green',
  LightPurple: 'light-purple',
  DarkPurple: 'dark-purple',
  LightPink: 'light-pink',
  DarkPink: 'dark-pink',
};

export const NAV_COLOR = {
  Default: 'default',
  Light: 'light',
  Dark: 'dark',
};
export const MENU_PLACEMENT = {
  Vertical: 'vertical',
  Horizontal: 'horizontal',
};
export const MENU_BEHAVIOUR = {
  Pinned: 'pinned',
  Unpinned: 'unpinned',
};

export const USER_ROLE = {
  Admin: 'admin',
  Editor: 'editor',
};

// export const GRAPHQL_API = 'http://18.196.190.184:8081/apis/graphql';
// export const GRAPHQL_API = 'http://3.127.107.202:8081/apis/graphql';
// export const GRAPHQL_API = 'http://35.157.25.185:8081/apis/graphql';
 export const GRAPHQL_API = 'https://platform.sustinerai.com/apis/graphql';

// export const REST_API = 'http://18.196.190.184:8081';
// export const REST_API = 'http://3.127.107.202:8081';
//   export const REST_API = 'http://35.157.25.185:8081/apis';
export const REST_API = 'https://platform.sustinerai.com/apis';
