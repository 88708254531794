import React from 'react';
import { Pagination } from 'react-bootstrap';
import CsLineIcons from 'cs-line-icons/CsLineIcons';

const TablePagination = ({ tableInstance }) => {
  const {
    gotoPage,
    canPreviousPage,
    previousPage,
    pageCount,
    nextPage,
    canNextPage,
    state: { pageIndex },
  } = tableInstance;

  if (pageCount <= 1) {
    return <></>;
  }
    
  const generatePaginationItems = () => {
    
    const itemsToShow = 10; 

    if (pageCount <= itemsToShow) {
      return [...Array(pageCount)].map((x, i) => (
        <Pagination.Item
          key={`pagination${i}`}
          className="shadow"
          active={pageIndex === i}
          onClick={() => gotoPage(i)}
        >
          {i + 1}
        </Pagination.Item>
      ));
    }
  
    const firstPageInCurrentSet = Math.floor(pageIndex / itemsToShow) * itemsToShow;
    const lastPageInCurrentSet =  Math.min(pageCount - 1, firstPageInCurrentSet + itemsToShow-1); 

    const items = [...Array(lastPageInCurrentSet - firstPageInCurrentSet + 1)].map((x, i) => (
      <Pagination.Item
        key={`pagination${firstPageInCurrentSet + i}_${i}`}
        className="shadow"
        active={pageIndex === firstPageInCurrentSet + i}
        onClick={() => gotoPage(firstPageInCurrentSet + i)}
      >
        {firstPageInCurrentSet + i + 1}
      </Pagination.Item>
    ));
  
    if (lastPageInCurrentSet < pageCount - 1) {
      items.push(<Pagination.Ellipsis key="ellipsis" onClick={() => gotoPage(lastPageInCurrentSet + 1)} />);
    }
  
    items.push(
      <Pagination.Item
        key={`pagination${pageCount - 1}`}
        className="shadow"
        active={pageIndex === pageCount - 1}
        onClick={() => gotoPage(pageCount - 1)}
      >
        {pageCount}
      </Pagination.Item>
    );
  
    return items;
  };
  return (
    <Pagination className="justify-content-center mb-0 mt-3">
      <Pagination.First className="shadow" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
        <CsLineIcons icon="arrow-double-left" />
      </Pagination.First>
      <Pagination.Prev className="shadow" disabled={!canPreviousPage} onClick={() => previousPage()}>
        <CsLineIcons icon="chevron-left" />
      </Pagination.Prev>
        {generatePaginationItems()}
      <Pagination.Next className="shadow" onClick={() => nextPage()} disabled={!canNextPage}>
        <CsLineIcons icon="chevron-right" />
      </Pagination.Next>
      <Pagination.Last className="shadow" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
        <CsLineIcons icon="arrow-double-right" />
      </Pagination.Last>
    </Pagination>
  );
};
export default TablePagination;
