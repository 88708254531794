import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  msg: null
};

const authChannelSlice = createSlice({
  name: 'authChannel',
  initialState,
  reducers: {
    sendMsg(state, action) {
      state.msg = action.payload;
    },
  },
});

export const sendAuthMsg = (msg) => async (dispatch) => {
  dispatch(authChannelSlice.actions.sendMsg(msg));
};


export default authChannelSlice.reducer;
