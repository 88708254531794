import React, { useEffect, useState } from "react";
import axios from "axios";
import { GRAPHQL_API } from "constants";
import { REGULATION_DETAILS } from "graphQL";
import { useTranslation } from "react-i18next";
import { Button, Col, Container, Row, Table } from "react-bootstrap";

const RegulationDetails = ({ id, handleClose }) => {
    const { t } = useTranslation();
    const [details, setDetails] = useState({});
    const [loading, setLoading] = useState(false);

    const fetchDetails = async () => {
        try {
            setLoading(true);
            const queryResult = await axios.post(GRAPHQL_API, {
                query: REGULATION_DETAILS,
                variables: { id },
            });
            const result = queryResult.data.data.getRegulationDetails;
            setDetails(result);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching Regulation Details:", error);
            setLoading(false);
            setDetails(null);
        }
    };

    useEffect(() => {
        fetchDetails();
    }, [id]);

    const reset = () => {
        handleClose();
    };

    if (loading) return <div>Loading...</div>;
    if (!details) return <div>No details available.</div>;

    // Destructure details with default empty arrays to avoid undefined errors
    const {
        overview = "",
        complianceTimeline = [],
        affectedSectors = [],
        riskAnalysis = [],
        GlobalMarketImpact = [],
        keyElements = []
    } = details;

    return (
        <Container className="p-4" style={{ fontFamily: "Arial, sans-serif", fontSize: "14px", lineHeight: "1.5" }}>
           {overview && (
          <Row className="mb-4">
            <Col>
            <h4 className="text-primary ">Overview</h4>
              <Table bordered>
                
                <tbody>
                  <tr>
                    <td>{overview}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
           )}
           {complianceTimeline && complianceTimeline.length > 0 && (
          <Row className="mb-4">
            <Col>
            <h4 className="text-primary ">Compliance Timeline</h4>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Milestone</th>
                  </tr>
                </thead>
                <tbody>
                  {complianceTimeline.map((item, index) => (
                    <tr key={index}>
                      <td>{item.Date}</td>
                      <td>{item.Milestone}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
           )}
          <Row className="mb-4">
          {affectedSectors && affectedSectors.length > 0 && (
            <Col md={6}>
            <h4 className="text-primary ">Affected Sectors</h4>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Sector</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {affectedSectors.map((sector, index) => (
                    <tr key={index}>
                      <td>{sector.Sector}</td>
                      <td>{sector.Description}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          )}
          {riskAnalysis && riskAnalysis.length > 0 && (
            <Col md={6}>
            <h4 className="text-primary ">Risk Analysis</h4>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Risk Type</th>
                    <th>Impact</th>
                  </tr>
                </thead>
                <tbody>
                  {riskAnalysis.map((risk, index) => (
                    <tr key={index}>
                      <td>{risk.Risk_Type}</td>
                      <td>{risk.Impact}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          )}
          </Row>

          <Row className="mb-4">
          {GlobalMarketImpact && GlobalMarketImpact.length > 0 && (
            <Col md={6}>
            <h4 className="text-primary ">Global Market Impact</h4>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Impact Area</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {GlobalMarketImpact.map((impact, index) => (
                    <tr key={index}>
                      <td>{impact.Impact_Area}</td>
                      <td>{impact.Description}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          )}
          {keyElements && keyElements.length > 0 && (
            <Col md={6}>
            <h4 className="text-primary ">key Elements</h4>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Requirement</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {keyElements.map((element, index) => (
                    <tr key={index}>
                      <td>{element.Requirement}</td>
                      <td>{element.Description}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          )}
          </Row>
          <Row className="justify-content-end mb-3">
            <Col xs="auto">
              <Button variant="primary" onClick={reset}>{t('Cancel')}</Button>
            </Col>
          </Row>
        </Container>
      );
};

export default RegulationDetails;
