// Services pour la gestion des utilisateurs

export const GET_USER_INFO = `
query getUserByEmail($email: String) {
    getUserByEmail(email: $email) {
      firstName
      lastName
      email
    }
  }
`;

export const LOCK_USER_ACCOUNT = `
mutation disableUserById($userId: String) {
  disableUserById(userId: $userId)
  }
`;

export const GET_USERS_LIST = `
query getUsersList {
    getUsersList {
      id
      firstName
      lastName
      email
      enabled
    }
  }
`;

// Services pour le filtrage des données au niveau Dashboard User


export const GET_COMPONIES_LIST = `
query getCompaniesList($page: Int, $size: Int) {
  getCompaniesList (page:$page, size:$size){
    data{
    date
    companyName
    sector
    country
    eScore
    sScore
    gScore
    esgScore
    }
    pageInfo{
      currentPage
      totalPages
      totalElement
    }
    }
  }
`;

export const GET_COMPANIES_BY_SCORES = `
query getCompaniesByScore($page: Int, $size: Int, $scoreRange: ScoreFilter) {
  getCompaniesByScore(page: $page, size: $size, scoreRange: $scoreRange){
    data{
      date
      companyName
      sector
      country
      eScore
      sScore
      gScore
      esgScore
      }
      pageInfo{
        currentPage
        totalPages
        totalElement
      }
  }

} 
`

export const GET_COMPANY_BY_ID = `
query getCompanyById($id: String) {
  getCompanyById(id: $id) {
    companyName
    }
  }
`;

export const GET_COMPANIES_NAMES = `
query getCompaniesNamesList {
  getCompaniesNamesList {
    label
    value
  }
  }
`;

export const GET_INDUSTRIES_LIST = `
query getIndustriesList {
  getIndustriesList
  }
`;

export const GET_COUNTRIES_LIST = `
query getCountriesList {
  getCountriesList
  }
`;

export const GET_COMPANIES_BY_NAME = `
query filterCompaniesByName($name: String){
  filterCompaniesByName(name: $name){
      companyName
      country
      industry
      sector
      date
      eScore
      sScore
      gScore
      esgScore
  }
}
`;
export const GET_LAST_SCORES = `
query loadLastScores($name: String){
  loadLastScores(name: $name){
      date
     companyName
      country
      sector
      eScore
      sScore
      gScore
      esgScore
  }
}
`;
export const GET_AVERAGE_SCORES = `
 query getAverageScores($industry: String){
  getAverageScores(industry: $industry){
    E_score
	  S_score
	  G_score
	  ESG_score
  }
 }
` 

export const GET_AVERAGE_SCORE = `
query getAverageScore($sector: String){
  getAverageScore(sector: $sector)
}
`;

export const GET_COMPANIES_BY_INDUSTRY = `
query filterCompaniesByIndustry($industry: String){
  filterCompaniesByIndustry(industry: $industry){
      id
      date
      companyName
      sector
      country
      eScore
      sScore
      gScore
      esgScore
      industry
  }
}
`;

export const GET_COMPANIES_BY_COUNTRY = `
query filterCompaniesByCountry($country: String){
  filterCompaniesByCountry(country: $country){
      id
      date
      companyName
      sector
      country
      eScore
      sScore
      gScore
      esgScore
      industry
  }
}
`;

export const GET_COMPANIES_BY_SECTOR_AND_COUNTRY = `
query filterCompaniesBySectorAndCountry($sector: String, $country: String){
  filterCompaniesBySectorAndCountry(sector: $sector, country: $country){
      id
      date
      companyName
      sector
      country
      eScore
      sScore
      gScore
      esgScore
      industry
  }
}
`;

export const GET_COMPANIES_BY_DATE_RANGE = `
query filterCompaniesByDateRange($name: String) {
  filterCompaniesByDateRange(name: $name) {
    id
    date
    companyName
    sector
    hqAddress
    country
    eScore
    sScore
    gScore
    esgScore
    industry
    }
  }
`;

// Services pour la gestion des Réclamations

export const CLAIM_ADMIN = `
mutation addClaim($category: String, $description: String, $userEmail: String) {
  addClaim(category: $category, description: $description, userEmail: $userEmail){
    category
    description
  }
  }
`;

export const EDIT_CLAIM = `
mutation editClaim($claimId: String) {
  editClaim(claimId: $claimId)
}
`;

export const GET_CLAIMS_LIST = `
query getClaimsList {
  getClaimsList{
    id
    category
    description
    dateOfClaim
    userEmail
    status
  }
}
`;

export const GET_CLAIMS_LIST_BY_USEREMAIL = `
query getClaimsListByUserEmail($userEmail: String) {
  getClaimsListByUserEmail(userEmail: $userEmail){
      category
      description
      dateOfClaim
      status
  }
}
`;

export const GET_CLAIMS_LIST_BY_STATUS = `
query getClaimsListByStatus($status: String) {
  getClaimsListByStatus(status: $status){
    id
    category
    description
    dateOfClaim
    userEmail
    status
  }
}
`;

// Services pour la gestion des articles

export const GET_KEYWORDS_LIST = `
query getKeywords($company: String, $category: String) {
  getKeywords(company: $company, category: $category){
    keyword
    total
    topicScore
  }
}
`;
export const GET_GRI_KEYWORDS_LIST = `
query getGRIKeywords($company: String, $category: String) {
  getGRIKeywords(company: $company, category: $category){
    keyword
    total
    topicScore
  }
}
`;

export const GET_KEYWORDS_DETAILS = `
query getDetailsKeyword($company: String, $category: String) {
  getDetailsKeyword(company: $company, category: $category){
    dateScraping
    paragraph
    link
    topic
    keywords
    key_words
    sentiment
    sentimentScore
    paraphrase
    topicScore
    uuid
   
  }
}
`;
export const GET_GRI_KEYWORDS_DETAILS = `
query getGRIDetailsKeyword($company: String, $category: String) {
  getGRIDetailsKeyword(company: $company, category: $category){
    dateScraping
    paragraph
    link
    topic
    keywords
    key_words
    sentiment
    sentimentScore
    paraphrase
    topicScore
    uuid
   
  }
}
`;
export const GET_TOPICELEMENTS_DETAILS = `
query getTopicElements($company: String, $category: String){
  getTopicElements(company: $company, category: $category){
    _id
    topic
    dateScraping
    score
  }
}
`;

export const REJECT_DATA=`
mutation addReject($uuid:String){
  addReject(uuid:$uuid)
} `

export const EXPORT_CSV_DATA=`
  query exportDataToCSV($company:String, $category: String){
    exportDataToCSV(company: $company, category: $category)
  }
`

export const ADD_COMPANY_TO_WATCH_LIST = `
mutation updateWatchlist($company:String, $addedCompany:String){
  updateWatchlist(company: $company, addedCompany: $addedCompany)
}
`

export const COMPANY_NAME_BY_USER_ID= `
  query getCompanyNameByUserId($userId: String){
    getCompanyNameByUserId(userId: $userId)
  }
`

export const WATCH_LIST_DETAILS= `
  query getWatchListDetails($companyName: String){
    getWatchListDetails(companyName: $companyName){
      companyName
      industry
      country
      eScore
      sScore
      gScore
      esgScore
    }
  } 
`
export const SUPPLIER_EVALUATION= `
  query getEvaluationScore($companyName: String){
    getEvaluationScore(companyName: $companyName){
          _id
          date
          Score
          companyName
          q11
          fileIdQ12
          q21
          fileIdQ22
          q31
          q32
          q41
          q42
          q51
          fileIdQ52
          q61
          q62
          q71
          q81
          q91
          q101
          fileIdQ102
    }
  } 
`
export const SUPPLIER_EVALUATION_BY_ID= `
  query getEvaluationScoreById($_id: String){
    getEvaluationScoreById(_id: $_id){
          _id
          date
          Score
          companyName
          q11
          fileIdQ12
          q21
          fileIdQ22
          q31
          q32
          q41
          q42
          q51
          fileIdQ52
          q61
          q62
          q71
          q81
          q91
          q101
          fileIdQ102
    }
  } 
`

export const DELETE_COMPANY_FROM_WATCH_LIST = `
mutation deleteCompanyFromWatchlist($companyName:String, $companyToRemove:String){
  deleteCompanyFromWatchlist(companyName: $companyName, companyToRemove: $companyToRemove)
}
`

export const ADD_NEW_SOURCE= `
  mutation addNewSource($type: String, $link: String, $userId: String){
    addNewSource(type: $type, link: $link, userId: $userId){
      
      date
      userId
      link
      type
    }
  }

`
export const PQQ_FILLER= `
  query getPQQFiller($companyName: String){
    getPQQFiller(companyName: $companyName)
  }
`
export const GET_SCORE= `
  query getScore($companyName: String){
    getScore(companyName: $companyName){
    _id
    date
    Score
    }
  }
`
export const GET_COMPANY_RELATION= `
query getCompanyRelation($companyName: String){
  getCompanyRelation(companyName: $companyName){
  Company
  Type_Relationship
  }
}
`;

export const GET_REGULATIONS_LIST = `
query getRegulationList($page: Int, $size: Int) {
  getRegulationList (page:$page, size:$size){
    data{
    id
    date
    name
    status
    region 
    }
    pageInfo{
      currentPage
      totalPages
      totalElement
    }
    }
  }
`;
export const GET_REGULATIONS_BY_COUNTRY = `
query getRegulationListByCountry($page: Int, $size: Int, $country: String) {
  getRegulationListByCountry (page:$page, size:$size, country: $country){
    data{
    id
    date
    name
    status
    region 
    }
    pageInfo{
      currentPage
      totalPages
      totalElement
    }
    }
  }
`;
export const COUNTRIES_LIST =`
query getCountries{
  getCountries
  }
  `
  export const REGULATION_DETAILS = `
  
  query getRegulationDetails($id: String){
    getRegulationDetails(id: $id){
      overview
      complianceTimeline{
        Date
        Milestone
      }
      affectedSectors{
        Description
        Sector
      }
      riskAnalysis{
        Risk_Type
        Impact
      }
      GlobalMarketImpact{
        Impact_Area
        Description
      }
      keyElements{
        Description
        Requirement
      }
    }}
  `
  export const GET_LAST_DETAILS = `
query getLastDetails($company: String, $category: String) {
  getLastDetails(company: $company, category: $category){
    _id  
    
    paragraph
    topic
    topicScore
    
  }
}
`;
export const GET_RISK_CATEGORY = `
query getRiskCategories($company: String, $category: String) {
  getRiskCategories(company: $company, category: $category){
    criticalRisk
    HighRisk
    AveragePerformance
    GoodPerformance
    HighPerforming
    
  }
}
`;
export const RELATION_SIZE =`
query getRelationSize($companyName: String){
  getRelationSize(companyName: $companyName)
  }
  `
  export const GET_DETAILS_BY_NAME = `
  query getDetailsByName($name: String) {
  getDetailsByName(name: $name){
    country
    sector
    industry
    
  }
}
`;

export const UPDATE_DOC=`
mutation updateDoc($name:String, $userID:String, $location:[String]){
  updateDoc(name:$name, userID:$userID, location:$location)
} `

export const ADD_NEW_DOC=`
mutation addDoc($name_company: String, $sector: String, $country: String, $industry: String, $users_ID: [String], $locations:[String]){
  addDoc(name_company: $name_company, sector: $sector,country: $country, industry: $industry, users_ID: $users_ID, locations: $locations){
    country
    sector
    industry
    name_company
  }
} `

export const GET_SECTORS_LIST = `
query getSectorsList {
  getSectorsList {
    label
    value
  }
  }
`;
export const GET_INDUS_LIST = `
query getIndusList {
  getIndusList {
    label
    value
  }
  }
`;

export const COMPANY_EXIST= `
  query companyNameExist($name: String){
    companyNameExist(name: $name)
  }
`