import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { GRAPHQL_API } from 'constants';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { COUNTRIES_LIST, GET_REGULATIONS_BY_COUNTRY, GET_REGULATIONS_LIST } from 'graphQL';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useGlobalFilter, usePagination, useRowSelect, useRowState, useSortBy, useTable } from 'react-table';
import UserService from 'services/UserService';
import Table from 'views/interface/plugins/datatables/EditableRows/components/Table';
import TablePagination from 'views/interface/plugins/datatables/EditableRows/components/TablePagination';
import ModalLayout from 'modals/ModalLayout';
import RegulationDetails from 'views/interface/components/RegulationDetails';
import { getData, setRegulationPage } from './RegulationSlice';

const DashboardRegulation = () => {
    const { t } = useTranslation();
    const title = t('dashboard_regulation_title');
    const regulationPage = useSelector((state) => state.DashboardRegulation);
    const regulation = regulationPage.items;
    const { pageInfo } = regulationPage;
    const dispatch = useDispatch();
    const [filteredData, setFilteredData] = useState(regulation);
    const setData = (data) => { dispatch(getData(data)) };
    const [isFiltering, setIsFiltering] = useState(false);
    const [country, setCountry] = useState('');
    const [countriesList, setCountriesList] = useState([]);
    const [isOpenAddEditModal, setIsOpenAddEditModal] = useState(false); 
    
    const columns = React.useMemo(() => {
        return [
            {
                Header: 'Date',
                accessor: 'date',
                sortable: true,
                selector: (row) => row.date,
                headerClassName: 'text-muted text-small text-uppercase w-20',
            },
            {
                Header: 'Name',
                accessor: 'name',
                sortable: true,
                selector: (row) => row.name,
                headerClassName: 'text-muted text-small text-uppercase w-10',
            },
            { Header: 'Status', accessor: 'status', sortable: true, selector: (row) => row.status, headerClassName: 'text-muted text-small text-uppercase w-10' },
            { Header: 'Region', accessor: 'region', sortable: true, selector: (row) => row.region, headerClassName: 'text-muted text-small text-uppercase w-10' },

            {
                Header: '',
                id: 'more',
                headerClassName: 'empty w-10',
                Cell: ({ row }) => (
                    <ModalLayout
                        modalButton={
                            <Button>
                                Details
                            </Button>
                        }
                        title={<h2 className="text-primary ">Regulation Details</h2>}
                        body={<RegulationDetails id={row.original.id} />}
                        size="xl"
                    />
                ),
            },
        ];
    }, []);

    const tableInstance = useTable(
        {
            columns,
            data: regulation,
            setData,
            initialState: {
                pageIndex: pageInfo.page,
                pageSize: pageInfo.pageSize,
            },
            manualPagination: true,
            pageCount: pageInfo.pageCount,
            autoResetPage: false,
            enablePagination: true,
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
        useRowState
    );

    const resetPaginator = (previousPage = 0) => {
        dispatch(setRegulationPage({ page: previousPage, pageSize: 25 }))
        tableInstance.gotoPage(previousPage)
    }

    const enableFilter = () => {
        setIsFiltering(true);
        resetPaginator()
    }

    const fetchCountries = async () => {
        try {
            const queryResult = await axios.post(GRAPHQL_API, {
                query: COUNTRIES_LIST,
            });
            const result = queryResult.data.data.getCountries;
            setCountriesList(result);
        } catch (error) {
            console.log(`Countries Fetch Error: ${error.message}`);
        }
    }

    useEffect(() => {
        fetchCountries();
    }, []);

    const fetchData = async () => {
        try {
            const queryResult = await axios.post(GRAPHQL_API, {
                query: GET_REGULATIONS_LIST,
                variables: { page: tableInstance.state.pageIndex, size: tableInstance.state.pageSize }
            });
            const result = queryResult.data.data;
            dispatch(getData(result.getRegulationList))
            setFilteredData(queryResult.data.data.getRegulationList.data);
        } catch (error) {
            console.log(`Data Fetch Error: ${error.message}`);
        }
    };

    useEffect(() => {
        if (!isFiltering) {
            fetchData();
        }
    }, [isFiltering, tableInstance.state.pageIndex, tableInstance.state.pageSize, dispatch]);

    const fetchFilteredData = async () => {
        try {
            const queryResult = await axios.post(GRAPHQL_API, {
                query: GET_REGULATIONS_BY_COUNTRY,
                variables: { page: tableInstance.state.pageIndex, size: tableInstance.state.pageSize, country }
            });
            const result = queryResult.data.data.getRegulationListByCountry;
            result.pageInfo.currentpage = 0
            dispatch(getData(result))
            setFilteredData(queryResult.data.data.getRegulationListByCountry.data);
        } catch (error) {
            console.log(`Data Fetch Error: ${error.message}`);
        }
    };

    useEffect(() => {
        if (isFiltering) {
            fetchFilteredData();
        }
    }, [isFiltering, country, tableInstance.state.pageIndex, tableInstance.state.pageSize, dispatch]);

    useEffect(() => {
        tableInstance.gotoPage(pageInfo.page)
    }, [pageInfo.page]);

    return (
        <>
         <Row>
                <div className="page-title-container">
                    <Row>
                        <Col xs="12" md="7">
                            <h1 className="mb-0 pb-0 display-4">{title}</h1>
                        </Col>
                    </Row>
                </div>
            </Row>
            <br />
           
            <Row className="mb-3 justify-content-center">
            <Col xs="12" md="5" lg="3">
                    <Form.Group controlId="countrySelect">
                        
                        <Form.Control
                            as="select"
                            value={country}
                            onChange={(e) => {
                                setCountry(e.target.value);
                                setIsFiltering(true); // Trigger filter when country changes
                            }}
                        >
                            <option value="-----">-- {t('third_filter_text')} --</option>
                            {countriesList.map((c) => (
                                <option key={c} value={c}>{c}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Col>
        </Row>

            
            <br />
            <br />
            <Row>
                <Col xs="12">
                <Table className="react-table rows" tableInstance={{...tableInstance, setIsOpenAddEditModal}} />

                </Col>
                <Col xs="12">
                    <TablePagination tableInstance={tableInstance} />
                </Col>
            </Row>
        </>
    );
}

export default DashboardRegulation;
